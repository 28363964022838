// import * as React from 'react';
// import { useState, useEffect } from "react";
// import Avatar from '@mui/material/Avatar';
// import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
// import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VicomtechLogoa from "../assets/vicomtech-logoa.png";
import CircularProgress from "@mui/material/CircularProgress";
import Copyright from './Copyright'

const defaultTheme = createTheme();

export default function FormLoading() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container fixed component="main" sx={{ height: "95vh", m: 0, p: 0 }}>
        <CssBaseline />
        <Grid item xs={12} sm={12} md={12} component={Paper} elevation={3}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "20%" }}>
              <img srcSet={VicomtechLogoa} src={VicomtechLogoa} alt="Vicomtech" loading="lazy" width="100%" />
            </Box>
            <Box sx={{ width: "100%" }}>
            <Typography component="h1" variant="h5" sx={{ mt: 5 }}>
              Loading, please wait...
            </Typography>
            <CircularProgress color="inherit" sx={{ mt: 5 }} />
            </Box>
            <Box position="fixed" bottom={20} width="100%" maxWidth="md" mx="auto">
              <Copyright sx={{ mb: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}


