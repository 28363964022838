import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VicomtechLogoa from "../assets/vicomtech-logoa.png";
import CircularProgress from "@mui/material/CircularProgress";
import Copyright from "./Copyright";
import { post } from "aws-amplify/api";


const defaultTheme = createTheme();

const backimages = ["img1.jpg", "img2.jpg", "img3.jpg", "img4.jpg", "img5.jpg", "img6.jpg", "img7.jpg", "img8.jpg", "img9.jpg"];

export default function FormType1(props) {
  const [randomImageNumber, setRandomImageNumber] = useState(0);
  const [formLoadingStatus, setFormLoadingStatus] = useState(0); // 0 = loading // 1 = ready // 2 = show confirmation // 9 = show error
  const [formData, setFormData] = useState({}); // Form data
  const [requestFullName, setRequestFullName] = useState("");
  const [requestEmail, setRequestEmail] = useState("");
  const [requestOrg, setRequestOrg] = useState("");
  const [requestLanguage, setRequestLanguage] = useState("");
  const [requestLegalAccepted, setRequestLegalAccepted] = useState(false);
  const [formId, setFormId] = useState(""); // Form id
  const [formSc, setFormSc] = useState(""); // Form sec code

  async function submitDatasetRequest(_id, _sc, _language, _fullname, _email, _org) {

    try {
      const _data = { id: _id, sc: _sc, language: _language, fullname: _fullname, email: _email, org: _org };
      const restOperation = post({
        apiName: "api826246e5",
        path: "/v1/api/submitdatasetrequest",
        options: {
          body: _data,
        },
      });

      const { statusCode, body } = await restOperation.response;
      const bodyJson = await body.json();
      if (statusCode === 200 && bodyJson.attention === "ok") {
        setFormLoadingStatus(2)
      } else {
        setFormLoadingStatus(9);
      }
    } catch (err) {
      // If request Fails change status to 9
      setFormLoadingStatus(9);
    }
  } 

  const handleSubmit = (event) => {
    event.preventDefault();

    if (requestEmail === "" || requestFullName === "" || requestOrg === "" || !requestLegalAccepted){
      setFormLoadingStatus(9);
    }else{
      submitDatasetRequest(formId, formSc, requestLanguage, requestFullName, requestEmail, requestOrg)
    }
    
  };

  useEffect(() => {
    // Set picked image
    setRandomImageNumber(Math.floor(Math.random() * backimages.length));

    setFormData(props.propFormData);
    setFormId(props.propFormId)
    setFormSc(props.propFormSc)

    setTimeout(() => {
      setFormLoadingStatus(1);
    }, 1500);

    setRequestLanguage("en")
  }, [props]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container fixed component="main" sx={{ height: "95vh", m: 0, p: 0 }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(/" + backimages[randomImageNumber] + ")",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        {formLoadingStatus == 0 && (
          // Loading
          <>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={3}>
              <Box
                sx={{
                  my: 4,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "30%" }}>
                  <img srcSet={VicomtechLogoa} src={VicomtechLogoa} alt="Vicomtech" loading="lazy" width="100%" />
                </Box>
                <Typography component="h1" variant="h5" sx={{ mt: 5 }}>
                  Loading dataset data, please wait
                </Typography>
                <CircularProgress color="inherit" sx={{ mt: 5 }} />
                {/* <Box position="fixed" bottom={10} width="100%" maxWidth="md" mx="auto"> */}
                <Copyright sx={{ mt: 10 }} />
                {/* </Box> */}
              </Box>
            </Grid>
          </>
        )}
        {formLoadingStatus == 1 && (
          // Ready
          <>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={3}>
              <Box
                sx={{
                  my: 4,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "30%" }}>
                  <img srcSet={VicomtechLogoa} src={VicomtechLogoa} alt="Vicomtech" loading="lazy" width="100%" />
                </Box>
                <Typography component="h1" variant="h5" sx={{mt: 3}}>
                  {formData.title}
                </Typography>
                <Typography component="h2" variant="subtitle1" sx={{ mt: 3 }}>
                  {formData.subtitle}
                </Typography>
                <Typography component="p" variant="caption" sx={{ mt: 3 }}>
                  In order to request access to the dataset, please fill and submit the form below
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    InputLabelProps={{ shrink: true }}  
                    onChange={(e)=>setRequestEmail(e.target.value)}
                    value={requestEmail}
                    
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="fullname"
                    label="Full Name"
                    type="text"
                    id="fullname"
                    autoComplete="name"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e)=>setRequestFullName(e.target.value)}
                    value={requestFullName}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="organisation"
                    label="Organisation"
                    type="text"
                    id="organisation"
                    autoComplete="organisation"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e)=>setRequestOrg(e.target.value)}
                    value={requestOrg}
                  />
                  <FormControlLabel
                    required
                    variant="caption"
                    control={<Checkbox value="remember" color="primary" />}
                    label={<Typography align="justify" variant="caption">I accept the <a href="https://www.vicomtech.org/en/legal-notice">terms of use</a> and <a href="https://www.vicomtech.org/en/privacy-policy">privacy policy</a></Typography>}
                    checked={requestLegalAccepted}
                    onChange={() => setRequestLegalAccepted(!requestLegalAccepted)}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={requestEmail === "" || requestFullName === "" || requestOrg === "" || !requestLegalAccepted}
                    >
                    Request access to dataset
                  </Button>
                  <Copyright sx={{ mt: 2 }} />
                </Box>
              </Box>
            </Grid>
          </>
        )}
        {formLoadingStatus == 2 && (
          // Request confirmed
          <>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={3}>
              <Box
                sx={{
                  my: 4,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "30%" }}>
                  <img srcSet={VicomtechLogoa} src={VicomtechLogoa} alt="Vicomtech" loading="lazy" width="100%" />
                </Box>
                <Typography component="h1" variant="h5" sx={{mt: 3}}>
                  Thank you for your interest
                </Typography>
                <Typography component="h2" variant="subtitle1" sx={{ mt: 3 }}>
                  You&apos;ll get the instructions on how to access the dataset on the provided email address.
                </Typography>
                <Box component="form" noValidate sx={{ mt: 4 }}>
                  For further information you can reach us out{" "}
                  <a href="https://www.vicomtech.org/en/contact" target="_blank" rel="noopener noreferrer">
                    here
                  </a>
                  .
                </Box>
                {/* <Box position="fixed" bottom={20} width="100%" maxWidth="md" mx="auto"> */}

                <Copyright sx={{ mt: 2 }} />
                {/* </Box> */}
              </Box>
            </Grid>
          </>
        )}
        {formLoadingStatus == 9 && (
          // Error
          <>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={3}>
              <Box
                sx={{
                  my: 4,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "30%" }}>
                  <img srcSet={VicomtechLogoa} src={VicomtechLogoa} alt="Vicomtech" loading="lazy" width="100%" />
                </Box>
                <Typography component="h1" variant="h5" sx={{mt: 3}}>
                  Request error. This dataset is no longer active or is having issues. Please retry or contact us.
                </Typography>
                <Box component="form" noValidate sx={{ mt: 4 }}>
                  For further information you can reach us out{" "}
                  <a href="https://www.vicomtech.org/en/contact" target="_blank" rel="noopener noreferrer">
                    here
                  </a>
                  .
                </Box>
                {/* <Box position="fixed" bottom={20} width="100%" maxWidth="md" mx="auto"> */}

                <Copyright sx={{ mt: 2 }} />
                {/* </Box> */}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </ThemeProvider>
  );
}
