// import * as React from "react";
import { useState, useEffect } from "react";

import { post } from "aws-amplify/api";
import FormLoading from "./FormLoading";
import FormType1 from "./FormType1";
import FormError from "./FormError";
import FormHome from "./FormHome";

export default function FormContainer() {
  const [formType, setFormType] = useState(0); // 0 = loading // 1 = type1 (This is set in the form json) // 9 = show error form // 10 = no parametres set, show home
  const [formId, setFormId] = useState(""); // Form id
  const [formSc, setFormSc] = useState(""); // Form sec code
  const [formData, setFormData] = useState({}); // Form data

  useEffect(() => {
    // Get params
    const path = window.location.pathname;
    // SPlit path
    var pathElements = path.split("/");

    setTimeout(() => {
      if (pathElements.length === 3) {
        // Set data
        setFormId(pathElements[1])
        setFormSc(pathElements[2])
        // Load data frmo datastore json
        getDatasetData(pathElements[1], pathElements[2]);
      } else {
        setFormType(10);
      }
    }, 1500);
  }, []);

  async function getDatasetData(_id, _sc) {

    try {
      const _data = { id: _id, sc: _sc };
      const restOperation = post({
        apiName: "api826246e5",
        path: "/v1/api/getdatasetdata",
        options: {
          body: _data,
        },
      });

      const { statusCode, body } = await restOperation.response;
      const bodyJson = await body.json();
      if (statusCode === 200 && (bodyJson.status === "1" || bodyJson.status === "0")) {
        setFormType(bodyJson.type);
        setFormData(bodyJson);
      } else {
        setFormType(9);
      }
    } catch (err) {
      // If request Fails change status to 9
      setFormType(9);
    }
  }

  return (
    <>
      {formType == 0 && <FormLoading />}
      {formType == 1 && <FormType1 propFormData={formData} propFormId={formId} propFormSc={formSc}/>}
      {formType == 9 && <FormError />}
      {formType == 10 && <FormHome />}
    </>
  );
}
