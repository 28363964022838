// import React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

export default function Copyright(props) {

  const BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION || "v0.0.0"; 

    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {"Copyright © "}
        <Link color="inherit" href="https://www.vicomtech.org/">
          Vicomtech
        </Link>{" "}
        {new Date().getFullYear()}
        {" - "}{BUILD_VERSION}
      </Typography>
    );
  }