import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VicomtechLogoa from "../assets/vicomtech-logoa.png";
import Copyright from './Copyright'


const defaultTheme = createTheme();

export default function FormError() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container fixed component="main" sx={{ height: "95vh", m: 0, p: 0 }}>
        <CssBaseline />
        <Grid item xs={12} sm={12} md={12} component={Paper} elevation={3}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "20%" }}>
              <img srcSet={VicomtechLogoa} src={VicomtechLogoa} alt="Vicomtech" loading="lazy" width="100%" />
            </Box>
            <Box sx={{ width: "100%" }}>
            <Typography component="h1" variant="h5" sx={{ mt: 5 }}>
              Dataset loading error, please try again or contact us.
            </Typography>
            </Box>
            <Box position="fixed" bottom={20} width="100%" maxWidth="md" mx="auto">
              <Copyright sx={{ mb: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

