import { Amplify } from "aws-amplify";
import config from "./amplifyconfiguration.json";
import './App.css'
import FormContainer from './components/FormContainer'

import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-PDSRPTHV'
}
 
TagManager.initialize(tagManagerArgs)

Amplify.configure(config);

function App() {

  return (
    <>
      <FormContainer />
    </>
  )
}

export default App